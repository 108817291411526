import React, { useState } from 'react'
import {
  getRestaurantsCollection,
  getRestaurantLocationsCollection,
  getRestaurantLocationMonthlyRevenuesCollection,
  getRestaurantOrdersCollection,
} from 'cf-core/src/config/firebase'
import { Card, CFView, PrimaryButton } from 'components'
import ExportStatsModal from './ExportStatsModal'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

const initialStats = {
  subtotal: 0,
  tax: 0,
  onlineTotal: 0,
  offlineTotal: 0,
  refund: 0,
  offlineRefund: 0,
  revenue: 0,
  tip: 0,
  commission: 0,
  stripeFee: 0,
  actualStripeFee: 0,
  subsFeePaid: 0,
  doordashFee: 0,
  doordashTip: 0,
  salesTax: 0,
  rewards: 0,
  rewardCount: 0,
  mobileCount: 0,
  webCount: 0,
  onlineCount: 0,
  pickupCount: 0,
  deliveryCount: 0,
  dineInCount: 0,
  cancelCount: 0,
  orderCount: 0,
  addChargeCount: 0,
  waitTimeUpdateCount: 0,
}

export default () => {
  const [restaurants, setRestaurants] = useState({})
  const [cfStats, setCFStats] = useState({ ...initialStats })
  const [fromDate, setFromDate] = useState(moment().startOf('day'))
  const [toDate, setToDate] = useState(moment().endOf('day'))
  const [saving, setSaving] = useState(false)
  const [processing, setProcessing] = useState('')
  const [showModal, setShowModal] = useState(false)

  const calculateStripeFee = (total) => {
    return total * 0.0225 + 0.4
  }

  const refreshData = async () => {
    setSaving(true)
    try {
      const restaurants = {}
      const CF_STATS = {
        ...initialStats,
      }
      const restaurantDocs = await getRestaurantsCollection().get()
      for (const doc of restaurantDocs.docs) {
        const restaurant = doc.data()
        const { name, inactive = false } = restaurant
        if (inactive) {
          continue
        }
        setProcessing(name)
        restaurants[doc.id] = restaurant
        restaurants[doc.id].id = doc.id
        restaurants[doc.id].Locations = {}
        restaurants[doc.id].Orders = {}
        restaurants[doc.id].locationStats = {}
        const locationDocs = await getRestaurantLocationsCollection({
          restaurantId: doc.id,
        }).get()
        const restaurantLocationsData = {}
        const restaurantLocationStats = {}
        const restaurantStats = {
          ...initialStats,
        }
        let orderDocs = []
        for (const locationDoc of locationDocs.docs) {
          restaurantLocationsData[locationDoc.id] = locationDoc.data()
          restaurantLocationsData[locationDoc.id].id = locationDoc.id
          const monthlyRevenueDocs =
            await getRestaurantLocationMonthlyRevenuesCollection({
              restaurantId: doc.id,
              locationId: locationDoc.id,
            }).get()
          let subsFeePaid = 0
          monthlyRevenueDocs.docs.length > 0 &&
            monthlyRevenueDocs.forEach((monthlyRevenueDoc) => {
              if (
                moment(monthlyRevenueDoc.id, 'YYYY-MM').isBetween(
                  fromDate,
                  toDate,
                  'month',
                  '[]'
                )
              ) {
                subsFeePaid += monthlyRevenueDoc.data().feePaid || 0
              }
            })
          CF_STATS.subsFeePaid += subsFeePaid
          restaurantStats.subsFeePaid += subsFeePaid
          restaurants[doc.id].locationStats[locationDoc.id] = {
            ...initialStats,
            subsFeePaid,
          }
          restaurantLocationStats[locationDoc.id] = {
            ...initialStats,
            subsFeePaid,
          }
          const locationOrderDocs = await getRestaurantOrdersCollection({
            restaurantId: doc.id,
          })
            .where('locationId', '==', locationDoc.id)
            .where('createdAt', '>', fromDate.valueOf())
            .where('createdAt', '<', toDate.valueOf())
            .orderBy('createdAt', 'desc')
            .get()
          orderDocs.push(...locationOrderDocs.docs)
        }
        restaurants[doc.id].Locations = restaurantLocationsData
        const restaurantOrdersData = {}
        if (orderDocs.length === 0) {
          restaurants[doc.id].Orders = restaurantOrdersData
          restaurants[doc.id].stats = restaurantStats
          continue
        }
        let processingCount = 0
        orderDocs.forEach((orderDoc) => {
          const order = orderDoc.data()
          restaurantOrdersData[orderDoc.id] = order
          restaurantOrdersData[orderDoc.id].id = orderDoc.id
          if (!restaurantLocationStats[order.locationId]) {
            restaurantLocationStats[order.locationId] = { ...initialStats }
          }
          if (order.status !== 'Cancelled') {
            CF_STATS.subtotal += order.subTotal
            restaurantStats.subtotal += order.subTotal
            restaurantLocationStats[order.locationId].subtotal += order.subTotal
            CF_STATS.tax += order.taxAmount
            restaurantStats.tax += order.taxAmount
            restaurantLocationStats[order.locationId].tax += order.taxAmount
            if (!order.paymentMethod || order.paymentMethod === 'online') {
              CF_STATS.onlineTotal += order.total
              restaurantStats.onlineTotal += order.total
              restaurantLocationStats[order.locationId].onlineTotal +=
                order.total
              CF_STATS.onlineCount++
              restaurantStats.onlineCount++
              restaurantLocationStats[order.locationId].onlineCount++
            } else {
              CF_STATS.offlineTotal += order.total
              restaurantStats.offlineTotal += order.total
              restaurantLocationStats[order.locationId].offlineTotal +=
                order.total
              CF_STATS.offlineRefund += order.totalRefundAmount || 0
              restaurantStats.offlineRefund += order.totalRefundAmount || 0
              restaurantLocationStats[order.locationId].offlineRefund +=
                order.totalRefundAmount || 0
            }
            CF_STATS.refund += order.totalRefundAmount || 0
            restaurantStats.refund += order.totalRefundAmount || 0
            restaurantLocationStats[order.locationId].refund +=
              order.totalRefundAmount || 0
            CF_STATS.revenue += order.revenue - (order.totalRefundAmount || 0)
            restaurantStats.revenue +=
              order.revenue - (order.totalRefundAmount || 0)
            restaurantLocationStats[order.locationId].revenue +=
              order.revenue - (order.totalRefundAmount || 0)
            CF_STATS.tip += order.tipAmount
            restaurantStats.tip += order.tipAmount
            restaurantLocationStats[order.locationId].tip += order.tipAmount
            CF_STATS.commission += order.commissionAmount
            restaurantStats.commission += order.commissionAmount
            restaurantLocationStats[order.locationId].commission +=
              order.commissionAmount
            CF_STATS.doordashFee += order.doordashFee || 0
            restaurantStats.doordashFee += order.doordashFee || 0
            restaurantLocationStats[order.locationId].doordashFee +=
              order.doordashFee || 0
            CF_STATS.stripeFee += order.stripeFeeAmount || 0
            restaurantStats.stripeFee += order.stripeFeeAmount || 0
            restaurantLocationStats[order.locationId].stripeFee +=
              order.stripeFeeAmount || 0
            if (
              moment(order.createdAt).isAfter(
                moment('2021-06-01').startOf('day')
              )
            ) {
              CF_STATS.salesTax +=
                (order.commissionAmount +
                  order.stripeFeeAmount +
                  order.doordashFee || 0) * 0.05
              restaurantStats.salesTax +=
                (order.commissionAmount +
                  order.stripeFeeAmount +
                  order.doordashFee || 0) * 0.05
              restaurantLocationStats[order.locationId].salesTax +=
                (order.commissionAmount +
                  order.stripeFeeAmount +
                  order.doordashFee || 0) * 0.05
            }
            CF_STATS.rewards += order.discount
            restaurantStats.rewards += order.discount
            restaurantLocationStats[order.locationId].rewards += order.discount
            CF_STATS.orderCount++
            restaurantStats.orderCount++
            restaurantLocationStats[order.locationId].orderCount++
            if (order.rewards) {
              Object.values(order.rewards).forEach(() => {
                CF_STATS.rewardCount++
                restaurantStats.rewardCount++
                restaurantLocationStats[order.locationId].rewardCount++
              })
            }
            if (order.sourceClient === 'mobile') {
              CF_STATS.mobileCount++
              restaurantStats.mobileCount++
              restaurantLocationStats[order.locationId].mobileCount++
            }
            if (order.orderType === 'Delivery') {
              CF_STATS.deliveryCount++
              restaurantStats.deliveryCount++
              restaurantLocationStats[order.locationId].deliveryCount++
              if (order.doordashFee > 0) {
                CF_STATS.doordashTip += order.tipAmount
                restaurantStats.doordashTip += order.tipAmount
                restaurantLocationStats[order.locationId].doordashTip +=
                  order.tipAmount
              }
            } else if (order.orderType === 'Dine-in') {
              CF_STATS.dineInCount++
              restaurantStats.dineInCount++
              restaurantLocationStats[order.locationId].dineInCount++
            }
            if (!order.paymentMethod || order.paymentMethod === 'online') {
              CF_STATS.actualStripeFee += calculateStripeFee(order.total)
              restaurantStats.actualStripeFee += calculateStripeFee(order.total)
              restaurantLocationStats[order.locationId].actualStripeFee +=
                calculateStripeFee(order.total)
            }
            CF_STATS.addChargeCount += order.addedCharges
              ? order.addedCharges.length
              : 0
            restaurantStats.addChargeCount += order.addedCharges
              ? order.addedCharges.length
              : 0
            restaurantLocationStats[order.locationId].addChargeCount +=
              order.addedCharges ? order.addedCharges.length : 0
            CF_STATS.waitTimeUpdateCount += order.waitTimeUpdateCount
              ? order.waitTimeUpdateCount
              : 0
            restaurantStats.waitTimeUpdateCount += order.waitTimeUpdateCount
              ? order.waitTimeUpdateCount
              : 0
            restaurantLocationStats[order.locationId].waitTimeUpdateCount +=
              order.waitTimeUpdateCount ? order.waitTimeUpdateCount : 0
          } else {
            if (order.appFeeAmount) {
              CF_STATS.commission += order.commissionAmount
              restaurantStats.commission += order.commissionAmount
              restaurantLocationStats[order.locationId].commission +=
                order.commissionAmount
              if (order.stripeFeeAmount > 0) {
                CF_STATS.stripeFee += order.stripeFeeAmount || 0
                restaurantStats.stripeFee += order.stripeFeeAmount || 0
                restaurantLocationStats[order.locationId].stripeFee +=
                  order.stripeFeeAmount || 0
                CF_STATS.actualStripeFee += calculateStripeFee(order.total)
                restaurantStats.actualStripeFee += calculateStripeFee(
                  order.total
                )
                restaurantLocationStats[order.locationId].actualStripeFee +=
                  calculateStripeFee(order.total)
              }
              if (
                moment(order.createdAt).isAfter(
                  moment('2021-06-01').startOf('day')
                )
              ) {
                CF_STATS.salesTax +=
                  (order.commissionAmount + order.stripeFeeAmount || 0) * 0.05
                restaurantStats.salesTax +=
                  (order.commissionAmount + order.stripeFeeAmount || 0) * 0.05
                restaurantLocationStats[order.locationId].salesTax +=
                  (order.commissionAmount + order.stripeFeeAmount || 0) * 0.05
              }
            } else {
              CF_STATS.commission -= calculateStripeFee(order.total)
              restaurantStats.commission -= calculateStripeFee(order.total)
              restaurantLocationStats[order.locationId].commission -=
                calculateStripeFee(order.total)
            }
            CF_STATS.cancelCount++
            restaurantStats.cancelCount++
            restaurantLocationStats[order.locationId].cancelCount++
          }
          processingCount++
          if (processingCount === orderDocs.length) {
            restaurants[doc.id].Orders = restaurantOrdersData
            restaurants[doc.id].stats = restaurantStats
            restaurants[doc.id].locationStats = restaurantLocationStats
          }
        })
      }
      setRestaurants(restaurants)
      setCFStats(CF_STATS)
    } catch (e) {
      console.log(e)
      alert('Error fetching data.')
    } finally {
      setSaving(false)
      setProcessing('')
    }
  }

  return (
    <CFView h="100%" p="20px" center>
      <CFView column>
        <Card h="calc(100vh - 110px)" w="1080px" column overflowX="auto">
          <CFView p="20px" row justifyBetween alignCenter bg="rgba(0,0,0,.3)">
            <CFView h1 bold>
              Statistics
            </CFView>
            <CFView row alignCenter>
              {Object.values(restaurants).length > 0 && (
                <PrimaryButton
                  w="200px"
                  disabled={saving}
                  label={'Export'}
                  onClick={() => setShowModal(true)}
                />
              )}
              <CFView w="20px" />
              <CFView>
                <CFView>From</CFView>
                <DatePicker value={fromDate} onChange={setFromDate} />
              </CFView>
              <CFView w="20px" />
              <CFView>
                <CFView>To</CFView>
                <DatePicker value={toDate} onChange={setToDate} />
              </CFView>
              <CFView w="20px" />
              <PrimaryButton
                w="200px"
                disabled={saving}
                label={saving ? 'Refreshing...' : 'Apply'}
                onClick={refreshData}
              />
            </CFView>
          </CFView>
          {Object.values(restaurants).length > 0 && !saving ? (
            <CFView p="20px" w="1080px" overflowX="auto">
              <CFView row style={{ flexWrap: 'wrap' }}>
                <Card m="10px" w="240px" p="20px" column>
                  <CFView mb="10px" bold>
                    CodeFusion
                  </CFView>
                  <CFView>
                    Total: $
                    {(cfStats.onlineTotal + cfStats.offlineTotal).toFixed(2)}
                  </CFView>
                  <CFView> - Online: ${cfStats.onlineTotal.toFixed(2)}</CFView>
                  <CFView>- Offline: ${cfStats.offlineTotal.toFixed(2)}</CFView>
                  <CFView>Subtotal: ${cfStats.subtotal.toFixed(2)}</CFView>
                  <CFView>Tax: ${cfStats.tax.toFixed(2)}</CFView>
                  <CFView>Refund: ${cfStats.refund.toFixed(2)}</CFView>
                  <CFView>
                    - Online: $
                    {(cfStats.refund - cfStats.offlineRefund).toFixed(2)}
                  </CFView>
                  <CFView>
                    - Offline: ${cfStats.offlineRefund.toFixed(2)}
                  </CFView>
                  <CFView>Revenue: ${cfStats.revenue.toFixed(2)}</CFView>
                  <CFView>
                    - Net Payable: $
                    {(
                      cfStats.revenue -
                      cfStats.offlineTotal +
                      cfStats.offlineRefund
                    ).toFixed(2)}
                  </CFView>
                  <CFView>Discount: ${cfStats.rewards.toFixed(2)}</CFView>
                  <CFView>Commission: ${cfStats.commission.toFixed(2)}</CFView>
                  <CFView>CF Fee: ${cfStats.stripeFee.toFixed(2)}</CFView>
                  <CFView>
                    Stripe Fee: $
                    {(
                      cfStats.actualStripeFee +
                      cfStats.addChargeCount * 0.4
                    ).toFixed(2)}
                  </CFView>
                  <CFView>
                    DoorDash Fee: ${cfStats.doordashFee.toFixed(2)}
                  </CFView>
                  <CFView>
                    Twilio Fee: $
                    {(cfStats.waitTimeUpdateCount * 0.0079).toFixed(2)}
                  </CFView>
                  <CFView>GST: ${cfStats.salesTax.toFixed(2)}</CFView>
                  <CFView>
                    Profit: $
                    {(
                      cfStats.commission +
                      cfStats.stripeFee -
                      cfStats.actualStripeFee -
                      cfStats.addChargeCount * 0.4 -
                      cfStats.waitTimeUpdateCount * 0.0079
                    ).toFixed(2)}
                  </CFView>
                  <CFView>
                    Subscription Paid: ${cfStats.subsFeePaid.toFixed(2)}
                  </CFView>
                  <CFView>Tip: ${cfStats.tip.toFixed(2)}</CFView>
                  <CFView>
                    DoorDash Tip: ${cfStats.doordashTip.toFixed(2)}
                  </CFView>
                  <CFView>Orders: {cfStats.orderCount}</CFView>
                  <CFView>- Online: {cfStats.onlineCount}</CFView>
                  <CFView>
                    - Offline: {cfStats.orderCount - cfStats.onlineCount}
                  </CFView>
                  <CFView>Cancelled: {cfStats.cancelCount}</CFView>
                  <CFView>Rewards: {cfStats.rewardCount}</CFView>
                  <CFView>Twilio Count: {cfStats.waitTimeUpdateCount}</CFView>
                  <CFView>
                    Online Ratio:{' '}
                    {cfStats.onlineCount > 0
                      ? parseInt(
                          (cfStats.onlineCount / cfStats.orderCount) * 100
                        )
                      : 0}
                    %
                  </CFView>
                  <CFView>
                    Mobile Ratio:{' '}
                    {cfStats.mobileCount > 0
                      ? parseInt(
                          (cfStats.mobileCount / cfStats.orderCount) * 100
                        )
                      : 0}
                    %
                  </CFView>
                  <CFView>
                    Delivery Ratio:{' '}
                    {cfStats.deliveryCount > 0
                      ? parseInt(
                          (cfStats.deliveryCount / cfStats.orderCount) * 100
                        )
                      : 0}
                    %
                  </CFView>
                  <CFView>
                    Dine-in Ratio:{' '}
                    {cfStats.dineInCount > 0
                      ? parseInt(
                          (cfStats.dineInCount / cfStats.orderCount) * 100
                        )
                      : 0}
                    %
                  </CFView>
                  <CFView>
                    Cancel Ratio:{' '}
                    {cfStats.cancelCount > 0
                      ? parseInt(
                          (cfStats.cancelCount /
                            (cfStats.orderCount + cfStats.cancelCount)) *
                            100
                        )
                      : 0}
                    %
                  </CFView>
                </Card>
                {Object.values(restaurants)
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1
                    }
                    if (a.name > b.name) {
                      return 1
                    }
                    return 0
                  })
                  .map((restaurant) => (
                    <React.Fragment key={restaurant.id}>
                      <Card m="10px" w="240px" p="20px" column>
                        <CFView mb="10px" bold>
                          {restaurant.name}
                        </CFView>
                        <CFView>
                          Total: $
                          {(
                            restaurant.stats.onlineTotal +
                            restaurant.stats.offlineTotal
                          ).toFixed(2)}
                        </CFView>
                        <CFView>
                          - TOnline: ${restaurant.stats.onlineTotal.toFixed(2)}
                        </CFView>
                        <CFView>
                          - TOffline: $
                          {restaurant.stats.offlineTotal.toFixed(2)}
                        </CFView>
                        <CFView>
                          Subtotal: ${restaurant.stats.subtotal.toFixed(2)}
                        </CFView>
                        <CFView>Tax: ${restaurant.stats.tax.toFixed(2)}</CFView>
                        <CFView>
                          Refund: ${restaurant.stats.refund.toFixed(2)}
                        </CFView>
                        <CFView>
                          - Online: $
                          {(
                            restaurant.stats.refund -
                            restaurant.stats.offlineRefund
                          ).toFixed(2)}
                        </CFView>
                        <CFView>
                          - Offline: $
                          {restaurant.stats.offlineRefund.toFixed(2)}
                        </CFView>
                        <CFView>
                          Revenue: ${restaurant.stats.revenue.toFixed(2)}
                        </CFView>
                        <CFView>
                          - Net Payable: $
                          {(
                            restaurant.stats.revenue -
                            restaurant.stats.offlineTotal +
                            restaurant.stats.offlineRefund
                          ).toFixed(2)}
                        </CFView>
                        <CFView>
                          Discount: ${restaurant.stats.rewards.toFixed(2)}
                        </CFView>
                        <CFView>
                          Commission: ${restaurant.stats.commission.toFixed(2)}
                        </CFView>
                        <CFView>
                          CF Fee: ${restaurant.stats.stripeFee.toFixed(2)}
                        </CFView>
                        <CFView>
                          Stripe Fee: $
                          {(
                            restaurant.stats.actualStripeFee +
                            restaurant.stats.addChargeCount * 0.4
                          ).toFixed(2)}
                        </CFView>
                        <CFView>
                          DoorDash Fee: $
                          {restaurant.stats.doordashFee.toFixed(2)}
                        </CFView>
                        <CFView>
                          Twilio Fee: $
                          {(
                            restaurant.stats.waitTimeUpdateCount * 0.0079
                          ).toFixed(2)}
                        </CFView>
                        <CFView>
                          GST: ${restaurant.stats.salesTax.toFixed(2)}
                        </CFView>
                        <CFView>
                          Profit: $
                          {(
                            restaurant.stats.commission +
                            restaurant.stats.stripeFee -
                            restaurant.stats.actualStripeFee -
                            restaurant.stats.addChargeCount * 0.4 -
                            restaurant.stats.waitTimeUpdateCount * 0.0079
                          ).toFixed(2)}
                        </CFView>
                        <CFView>
                          Subscription Paid: $
                          {restaurant.stats.subsFeePaid.toFixed(2)}
                        </CFView>
                        <CFView>Tip: ${restaurant.stats.tip.toFixed(2)}</CFView>
                        <CFView>
                          DoorDash Tip: $
                          {restaurant.stats.doordashTip.toFixed(2)}
                        </CFView>
                        <CFView>Orders: {restaurant.stats.orderCount}</CFView>
                        <CFView>
                          - Online: {restaurant.stats.onlineCount}
                        </CFView>
                        <CFView>
                          - Offline:{' '}
                          {restaurant.stats.orderCount -
                            restaurant.stats.onlineCount}
                        </CFView>
                        <CFView>
                          Cancelled: {restaurant.stats.cancelCount}
                        </CFView>
                        <CFView>Rewards: {restaurant.stats.rewardCount}</CFView>
                        <CFView>
                          Twilio Count: {restaurant.stats.waitTimeUpdateCount}
                        </CFView>
                        <CFView>
                          Online Ratio:{' '}
                          {restaurant.stats.onlineCount > 0
                            ? parseInt(
                                (restaurant.stats.onlineCount /
                                  restaurant.stats.orderCount) *
                                  100
                              )
                            : 0}
                          %
                        </CFView>
                        <CFView>
                          Mobile Ratio:{' '}
                          {restaurant.stats.mobileCount > 0
                            ? parseInt(
                                (restaurant.stats.mobileCount /
                                  restaurant.stats.orderCount) *
                                  100
                              )
                            : 0}
                          %
                        </CFView>
                        <CFView>
                          Delivery Ratio:{' '}
                          {restaurant.stats.deliveryCount > 0
                            ? parseInt(
                                (restaurant.stats.deliveryCount /
                                  restaurant.stats.orderCount) *
                                  100
                              )
                            : 0}
                          %
                        </CFView>
                        <CFView>
                          Dine-in Ratio:{' '}
                          {restaurant.stats.dineInCount > 0
                            ? parseInt(
                                (restaurant.stats.dineInCount /
                                  restaurant.stats.orderCount) *
                                  100
                              )
                            : 0}
                          %
                        </CFView>
                        <CFView>
                          Cancel Ratio:{' '}
                          {restaurant.stats.cancelCount > 0
                            ? parseInt(
                                (restaurant.stats.cancelCount /
                                  (restaurant.stats.orderCount +
                                    restaurant.stats.cancelCount)) *
                                  100
                              )
                            : 0}
                          %
                        </CFView>
                      </Card>
                      {Object.values(restaurant.Locations).length > 1 &&
                        Object.values(restaurant.Locations).map(
                          (location) =>
                            restaurant.locationStats[location.id] && (
                              <Card
                                key={location.id}
                                m="10px"
                                w="240px"
                                p="20px"
                                column
                              >
                                <CFView mb="10px" bold>
                                  {restaurant.name} - {location.locationName}
                                </CFView>
                                <CFView>
                                  Total: $
                                  {(
                                    restaurant.locationStats[location.id]
                                      .onlineTotal +
                                    restaurant.locationStats[location.id]
                                      .offlineTotal
                                  ).toFixed(2)}
                                </CFView>
                                <CFView>
                                  - Online: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].onlineTotal.toFixed(2)}
                                </CFView>
                                <CFView>
                                  - Offline: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].offlineTotal.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Subtotal: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].subtotal.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Tax: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].tax.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Refund: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].refund.toFixed(2)}
                                </CFView>
                                <CFView>
                                  - Online: $
                                  {(
                                    restaurant.locationStats[location.id]
                                      .refund -
                                    restaurant.locationStats[location.id]
                                      .offlineRefund
                                  ).toFixed(2)}
                                </CFView>
                                <CFView>
                                  - Offline: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].offlineRefund.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Revenue: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].revenue.toFixed(2)}
                                </CFView>
                                <CFView>
                                  - Net Payable: $
                                  {(
                                    restaurant.locationStats[location.id]
                                      .revenue -
                                    restaurant.locationStats[location.id]
                                      .offlineTotal +
                                    restaurant.locationStats[location.id]
                                      .offlineRefund
                                  ).toFixed(2)}
                                </CFView>
                                <CFView>
                                  Discount: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].rewards.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Commission: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].commission.toFixed(2)}
                                </CFView>
                                <CFView>
                                  CF Fee: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].stripeFee.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Stripe Fee: $
                                  {(
                                    restaurant.locationStats[location.id]
                                      .actualStripeFee +
                                    restaurant.locationStats[location.id]
                                      .addChargeCount *
                                      0.4
                                  ).toFixed(2)}
                                </CFView>
                                <CFView>
                                  DoorDash Fee: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].doordashFee.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Twilio Fee: $
                                  {(
                                    restaurant.locationStats[location.id]
                                      .waitTimeUpdateCount * 0.0079
                                  ).toFixed(2)}
                                </CFView>
                                <CFView>
                                  GST: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].salesTax.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Profit: $
                                  {(
                                    restaurant.locationStats[location.id]
                                      .commission +
                                    restaurant.locationStats[location.id]
                                      .stripeFee -
                                    restaurant.locationStats[location.id]
                                      .actualStripeFee -
                                    restaurant.locationStats[location.id]
                                      .addChargeCount *
                                      0.4 -
                                    restaurant.locationStats[location.id]
                                      .waitTimeUpdateCount *
                                      0.0079
                                  ).toFixed(2)}
                                </CFView>
                                <CFView>
                                  Subscription Paid: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].subsFeePaid.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Tip: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].tip.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Commission Paid: $
                                  {restaurant.locationStats[
                                    location.id
                                  ].doordashTip.toFixed(2)}
                                </CFView>
                                <CFView>
                                  Orders:{' '}
                                  {
                                    restaurant.locationStats[location.id]
                                      .orderCount
                                  }
                                </CFView>
                                <CFView>
                                  - Online:{' '}
                                  {
                                    restaurant.locationStats[location.id]
                                      .onlineCount
                                  }
                                </CFView>
                                <CFView>
                                  - Offline:{' '}
                                  {restaurant.locationStats[location.id]
                                    .orderCount -
                                    restaurant.locationStats[location.id]
                                      .onlineCount}
                                </CFView>
                                <CFView>
                                  Cancelled:{' '}
                                  {
                                    restaurant.locationStats[location.id]
                                      .cancelCount
                                  }
                                </CFView>
                                <CFView>
                                  Rewards:{' '}
                                  {
                                    restaurant.locationStats[location.id]
                                      .rewardCount
                                  }
                                </CFView>
                                <CFView>
                                  Twilio Count:{' '}
                                  {
                                    restaurant.locationStats[location.id]
                                      .waitTimeUpdateCount
                                  }
                                </CFView>
                                <CFView>
                                  Online Ratio:{' '}
                                  {restaurant.locationStats[location.id]
                                    .onlineCount > 0
                                    ? parseInt(
                                        (restaurant.locationStats[location.id]
                                          .onlineCount /
                                          restaurant.locationStats[location.id]
                                            .orderCount) *
                                          100
                                      )
                                    : 0}
                                  %
                                </CFView>
                                <CFView>
                                  Mobile Ratio:{' '}
                                  {restaurant.locationStats[location.id]
                                    .mobileCount > 0
                                    ? parseInt(
                                        (restaurant.locationStats[location.id]
                                          .mobileCount /
                                          restaurant.locationStats[location.id]
                                            .orderCount) *
                                          100
                                      )
                                    : 0}
                                  %
                                </CFView>
                                <CFView>
                                  Delivery Ratio:{' '}
                                  {restaurant.locationStats[location.id]
                                    .deliveryCount > 0
                                    ? parseInt(
                                        (restaurant.locationStats[location.id]
                                          .deliveryCount /
                                          restaurant.locationStats[location.id]
                                            .orderCount) *
                                          100
                                      )
                                    : 0}
                                  %
                                </CFView>
                                <CFView>
                                  Dine-in Ratio:{' '}
                                  {restaurant.locationStats[location.id]
                                    .dineInCount > 0
                                    ? parseInt(
                                        (restaurant.locationStats[location.id]
                                          .dineInCount /
                                          restaurant.locationStats[location.id]
                                            .orderCount) *
                                          100
                                      )
                                    : 0}
                                  %
                                </CFView>
                                <CFView>
                                  Cancel Ratio:{' '}
                                  {restaurant.locationStats[location.id]
                                    .cancelCount > 0
                                    ? parseInt(
                                        (restaurant.locationStats[location.id]
                                          .cancelCount /
                                          (restaurant.locationStats[location.id]
                                            .orderCount +
                                            restaurant.locationStats[
                                              location.id
                                            ].cancelCount)) *
                                          100
                                      )
                                    : 0}
                                  %
                                </CFView>
                              </Card>
                            )
                        )}
                    </React.Fragment>
                  ))}
              </CFView>
            </CFView>
          ) : processing ? (
            <CFView h2 bold fill center>
              {`Processing ${processing}...`}
            </CFView>
          ) : (
            <CFView h2 bold fill center>
              No Data
            </CFView>
          )}
        </Card>
      </CFView>
      <ExportStatsModal
        restaurants={restaurants}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </CFView>
  )
}
